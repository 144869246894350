<template>
  <div class="app-container">
    <div class="outer">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div class="component">
          <h1 class="title">1、模板基础信息</h1>

          <el-form-item label="模板名称：" prop="name">
            <el-input
              v-model="ruleForm.name"
              class="width360"
              size="mini"
              maxlength="20"
              show-word-limit
              placeholder="请输入模板名称"
            ></el-input>
            <input style="display: none" type="text">
          </el-form-item>
        </div>
        <div class="component">
          <h1 class="title">2、配送地区设置</h1>

          <el-form-item required label="选择地区：" prop="area">
            <!-- <el-cascader
              v-model="ruleForm.areaData"
              class="width360"
              :options="options"
              :props="{
                checkStrictly: true,
                value: 'regionCode',
                label: 'regionName',
                expandTrigger: 'hover'
              }"
              clearable
              size="mini"
              @change="handleChange"
            /> -->
            <div class="area-tag-box">
              <el-tag
                v-for="(item, index) in ruleForm.areaList"
                @close="removeCity(ruleForm.area, item, index)"
                class="tags"
                :key="index"
                closable
                size="mini"
                >{{ item.fullName }}</el-tag
              >
            </div>
            <el-button
              style="margin-left: 10px; height: 30px"
              size="mini"
              @click="showCitySelectModal = true"
            >
              编辑
            </el-button>
          </el-form-item>
        </div>
      </el-form>

      <div class="component">
        <h1 class="title">3、计费规则</h1>
        <el-form label-width="110px" class="demo-ruleForm">
          <el-form-item required label="计费类型：">
            <el-radio-group
              v-model="ruleForm.billType"
              :disabled="query.type === 'edit'"
            >
              <el-radio :label="0"> 免费配送 </el-radio>
              <el-radio :label="1"> 按件计费 </el-radio>
              <!-- <el-radio :label="2">按重量计费</el-radio> -->
            </el-radio-group>

            <el-table
              v-if="ruleForm.billType === 1 || ruleForm.billType === 2"
              :data="tradeBillCommonDtoList"
              border
              size="mini"
              :header-cell-style="{
                background: '#d9dde1',
                color: '#0d0202',
                padding: 0,
              }"
            >
              <el-table-column
                :label="ruleForm.billType === 1 ? '首件数（件）' : '首重（kg）'"
              >
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.firstNum"
                    style="width: 100%"
                    placeholder="请输入"
                    size="mini"
                    :min="0"
                    :max="999999"
                  />
                </template>
              </el-table-column>
              <el-table-column label="首费">
                <template slot-scope="scope">
                  <el-input-number
                    v-model="scope.row.firstFee"
                    style="width: 100%"
                    placeholder="请输入"
                    size="mini"
                    :min="0"
                    :max="999999"
                  />
                </template>
              </el-table-column>
              <el-table-column label="是否条件包邮">
                <template slot-scope="scope">
                  <el-select
                    v-model="scope.row.isShip"
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option label="是" :value="1"> </el-option>
                    <el-option label="否" :value="0"> </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="包邮条件" width="300">
                <template v-if="scope.row.isShip === 1" slot-scope="scope">
                  <div style="display: flex; margin: 0 3px">
                    满
                    <el-input-number
                      v-model="scope.row.shipCondition"
                      style="width: 130px"
                      placeholder="请输入"
                      size="mini"
                      :min="0"
                      :max="999999"
                    />
                    <el-select
                      v-model="scope.row.isFee"
                      style="width: 70px"
                      size="mini"
                    >
                      <el-option
                        :label="ruleForm.billType == 1 ? '件' : 'kg'"
                        :value="0"
                      >
                      </el-option>
                      <el-option :label="'元'" :value="1"> </el-option>
                    </el-select>
                    <div style="min-width: 30px; margin: 0 3px">包邮</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-button type="primary" size="small" @click="submitForm">
          提 交
        </el-button>
        <el-button size="small" @click="toBack()"> 返回 </el-button>
      </div>
    </div>

    <!-- 城市多选弹框 -->
    <CitySelectModal
      :cityList="defaultCityList"
      @confirm="confirmArea"
      v-model="showCitySelectModal"
    >
    </CitySelectModal>
  </div>
</template>

<script>
import "./style.scss";
import CitySelectModal from "./components/CitySelectModal.vue";
export default {
  components: {
    CitySelectModal,
  },
  data() {
    return {
      defaultCityList: [],
      showCitySelectModal: false,
      query: {},
      tradeBillCommonDtoList: [{}],
      options: [],

      ruleForm: {
        name: "",
        billType: 0,
        area: {},
        areaData: [],
      },
      rules: {
        name: [
          { required: true, message: "请输入模板名称", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    this.query = this.$route.query;
    if (this.query.type === "edit") {
      this.gitData();
    } else if (this.query.type === "add") {
      this.getCatList(0);
    }
  },
  methods: {
    confirmArea(data) {
      const { fullList, list } = data;
      //包邮
      this.ruleForm.area = list;

      if (this.ruleForm.areaList) {
        this.ruleForm.areaList = fullList;
      } else {
        this.$set(this.ruleForm, "areaList", fullList);
      }
    },
    getNowCat(id) {
      let that = this;
      return new Promise((resolve) => {
        that
          .$axios({
            method: "get",
            url: that.$api.commonNew.getChildRegion,
            params: {
              regionCode: id,
            },
          })
          .then((res) => {
            if (res.code === 0) {
              resolve(res.data);
            }
          });
      });
    },
    async getAllCatList(path, defaultList) {
      let _this = this;
      let nowCatList = await _this.getNowCat(0);
      _this.options = nowCatList;
      if (path[0] != 0) {
        const setAllList = async (idList, array) => {
          if (idList.length > 0) {
            let nowList = await _this.getNowCat(path[0]);
            array.forEach((item) => {
              if (item.regionCode == idList[0]) {
                if (nowList.length > 0) {
                  _this.$set(item, "children", nowList);
                }
              }
            });
            idList.shift();
            setAllList(idList, nowList);
          } else {
            _this.$nextTick(() => {
              _this.ruleForm.areaData = defaultList;
              this.$refs.ruleForm.validateField("areaData");
            });
          }
        };
        setAllList(path, _this.options);
      }
    },
    gitData() {
      this.$axios
        .get(this.$api.trade_template_mail.detail, {
          params: {
            templateType: this.query.templateType,
            templateId: this.query.id,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.ruleForm = {
              ...res.data,
              billType: res.data.tradeBillCommonDtoList
                ? res.data.tradeBillCommonDtoList[0].billType
                : 0,
            };
            // let newList = res.data.tradeBillCommonDtoList[0].area.match(/\d+/g);
            // console.log(newList);
            // this.getAllCatList([...newList], [...newList]); // 地区回填

            this.$set(
              this.ruleForm,
              "area",
              JSON.parse(res.data.tradeBillCommonDtoList[0].area || "{}")
            );

            this.$set(
              this.ruleForm,
              "areaList",
              res.data.tradeBillCommonDtoList[0].areaList
            );

            // let defaultAreaList = [];
            // this.initDefaultCity(
            //   defaultAreaList,
            //   JSON.parse(res.data.tradeBillCommonDtoList[0].area || "{}")
            // );
            this.defaultCityList = res.data.tradeBillCommonDtoList[0].areaList;

            this.tradeBillCommonDtoList = res.data.tradeBillCommonDtoList;
          }
        });
    },

    initDefaultCity(arr, areaData) {
      if (Array.isArray(areaData)) {
        areaData.forEach((e) => {
          if (typeof e == "string") {
            arr.push(e);
          } else {
            let objKey = Object.keys(e)[0];
            if (e[objKey].length) {
              this.initDefaultCity(arr, e[objKey]);
            } else {
              arr.push(objKey);
            }
          }
        });
      } else {
        Object.keys(areaData).forEach((key) => {
          if (areaData[key].length) {
            this.initDefaultCity(arr, areaData[key]);
          } else {
            arr.push(key);
          }
        });
      }
    },
    removeCity(list, item, index) {
      if (item.regionCode.length <= 4) {
        Object.keys(list).forEach((key) => {
          //省
          if (key == item.regionCode) {
            delete list[key];
          } else {
            if (key[0] == item.regionCode[0] && key[1] == item.regionCode[1]) {
              if (list[key].length <= 1) {
                delete list[key];
              } else {
                let filterIndex = list[key].findIndex((e) => {
                  let cityKey = Object.keys(e)[0];
                  if (cityKey == item.regionCode) {
                    return true;
                  }
                });

                list[key].splice(filterIndex, 1);
              }
            }
          }
        });
      }

      if (item.regionCode.length == 6) {
        //区
        Object.keys(list).forEach((key) => {
          //省

          if (key[0] == item.regionCode[0] && key[1] == item.regionCode[1]) {
            let cityLength = list[key].length;

            list[key].forEach((e, i) => {
              //市
              let ctyKey = Object.keys(e)[0];
              let countryLen = e[ctyKey].length;
            
              let filterIndex = e[ctyKey].findIndex((se) => {
                let cKey;
                if(typeof se === 'string') {
                  cKey = se;
                } else {
                  cKey = Object.keys(se)[0];
                }
                if (cKey == item.regionCode) {
                  return true;
                }
              });

              if (filterIndex >= 0) {
                if (countryLen > 1) {
                  e[ctyKey].splice(filterIndex, 1);
                } else {
                  if (cityLength > 1) {
                    list[key].splice(i, 1);
                  } else {
                    delete list[key];
                  }
                }
              }
            });
          }
        });
      }

      if (item.regionCode.length > 6) {
        //街道
        Object.keys(list).forEach((key) => {
          //省

          if (key[0] == item.regionCode[0] && key[1] == item.regionCode[1]) {
            let cityLength = list[key].length;

            list[key].forEach((e, i) => {
              //市
              let ctyKey = Object.keys(e)[0];
              let countryLen = e[ctyKey].length;

              e[ctyKey].forEach((c, ci) => {
                //区
                let qKey = Object.keys(c)[0];
                let jLen = c[qKey].length;

                let filterIndex = c[qKey].findIndex((s) => {
                  let skey = Object.keys(s)[0];
                  if (skey == item.regionCode) {
                    return true;
                  }
                });

                if (filterIndex >= 0) {
                  if (jLen > 1) {
                    c[qKey].splice(filterIndex, 1);
                  } else {
                    if (countryLen > 1) {
                      e[ctyKey].splice(ci, 1);
                    } else {
                      if (cityLength > 1) {
                        list[key].splice(i, 1);
                      } else {
                        delete list[key];
                      }
                    }
                  }
                }
              });
            });
          }
        });
      }
      this.ruleForm.areaList.splice(index, 1);
      this.defaultCityList = this.ruleForm.areaList;
      console.log(this.ruleForm.area, this.ruleForm.areaList)
    },
    submitForm() {
      if (!Object.keys(this.ruleForm.area).length) {
        this.$message.warning("请选择配送区域");
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.billType !== 0) {
            for (let row of this.tradeBillCommonDtoList) {
              if (
                !row.firstNum ||
                !row.firstFee ||
                (row.isShip !== 0 && row.isShip !== 1) ||
                (row.isShip === 1 && !row.shipCondition) ||
                (row.isShip === 1 && row.isFee !== 0 && row.isFee !== 1)
              ) {
                this.$message({ type: "warning", message: "请完善价格表" });
                return;
              }
            }
          }
          this.$confirm("是否确认提交?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              // let newarea = {}
              // let length = this.ruleForm.areaData.length
              // let a = this.ruleForm.areaData[0]
              // let b = this.ruleForm.areaData[1]
              // let c = this.ruleForm.areaData[2]
              // let d = this.ruleForm.areaData[3]
              // if (length === 1) {
              //   newarea = { [a]: [] }
              // } else if (length === 2) {
              //   newarea = { [a]: [{ [b]: [] }] }
              // } else if (length === 3) {
              //   newarea = { [a]: [{ [b]: [c] }] }
              // } else if (length === 4) {
              //   newarea = { [a]: [{ [b]: [c] }] }
              // }

              let params = {
                ...this.ruleForm,
                templateType: 2, // 普通1 同城2
                name: this.ruleForm.name,
                tradeBillCommonDtoList: [
                  {
                    ...this.tradeBillCommonDtoList[0],
                    area: JSON.stringify(this.ruleForm.area),
                    areaFullPath: JSON.stringify(this.ruleForm.areaList),
                    billType: this.ruleForm.billType,
                    name: this.ruleForm.name,
                  },
                ],
                storeId: this.$route.query.storeId
                  ? this.$route.query.storeId
                  : "",
              };
              let apiurl = this.$api.trade_template_mail.add;
              if (this.query.type === "edit") {
                apiurl = this.$api.trade_template_mail.edit;
              }
              this.$axios.post(apiurl, params).then((res) => {
                if (res.code === 0) {
                  this.$message({ type: "success", message: "操作成功！" });
                  this.$router.push({
                    path: "/nb/goods/freight",
                    query: {
                      freightType: 2,
                    },
                  });
                }
              });
            })
            .catch(() => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    activeitemchange(e) {
      console.log(e, 1111);
    },
    handleChange(value) {
      console.log(value);
      let _this = this;
      let id = value[value.length - 1];
      if (!value || value.length >= 3) {
        return;
      }

      let findCatList = (array) => {
        array.forEach((item) => {
          if (item.regionCode == id) {
            _this.getCatList(id, item);
          } else {
            if (item.children) {
              findCatList(item.children);
            }
          }
        });
      };
      findCatList(_this.options);
    },
    // 获得类目数据
    getCatList(id, obj) {
      let that = this;
      if (obj && obj.children) {
        return;
      }
      that
        .$axios({
          method: "get",
          url: that.$api.commonNew.getChildRegion,
          params: {
            regionCode: id,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            if (res.data.length > 0) {
              if (id == 0) {
                that.options = res.data;
              } else {
                that.$set(obj, "children", res.data);
              }
            }
          }
        });
    },
    toBack() {
      if (this.query.type === "show") {
        this.$router.go(-1);
        return;
      }
      this.$confirm("确定返回列表吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.area-tag-box {
  width: 360px;
  min-height: 160px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  line-height: 2;
  .tags {
    margin-right: 10px;
  }
}
.el-cascader-menu__wrap {
  height: 300px;
}
</style>
