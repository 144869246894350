// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.area-tag-box {\n  width: 360px;\n  min-height: 160px;\n  border: 1px solid #dcdfe6;\n  border-radius: 4px;\n  display: inline-block;\n  vertical-align: top;\n  padding: 0 10px;\n  line-height: 2;\n}\n.area-tag-box .tags {\n  margin-right: 10px;\n}\n.el-cascader-menu__wrap {\n  height: 300px;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
