var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "110px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "component" },
                [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v("1、模板基础信息"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "模板名称：", prop: "name" } },
                    [
                      _c("el-input", {
                        staticClass: "width360",
                        attrs: {
                          size: "mini",
                          maxlength: "20",
                          "show-word-limit": "",
                          placeholder: "请输入模板名称",
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                      _c("input", {
                        staticStyle: { display: "none" },
                        attrs: { type: "text" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "component" },
                [
                  _c("h1", { staticClass: "title" }, [
                    _vm._v("2、配送地区设置"),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        required: "",
                        label: "选择地区：",
                        prop: "area",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "area-tag-box" },
                        _vm._l(_vm.ruleForm.areaList, function (item, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              staticClass: "tags",
                              attrs: { closable: "", size: "mini" },
                              on: {
                                close: function ($event) {
                                  return _vm.removeCity(
                                    _vm.ruleForm.area,
                                    item,
                                    index
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.fullName))]
                          )
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-left": "10px",
                            height: "30px",
                          },
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.showCitySelectModal = true
                            },
                          },
                        },
                        [_vm._v(" 编辑 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "component" },
            [
              _c("h1", { staticClass: "title" }, [_vm._v("3、计费规则")]),
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  attrs: { "label-width": "110px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { required: "", label: "计费类型：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.query.type === "edit" },
                          model: {
                            value: _vm.ruleForm.billType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "billType", $$v)
                            },
                            expression: "ruleForm.billType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v(" 免费配送 "),
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 按件计费 "),
                          ]),
                        ],
                        1
                      ),
                      _vm.ruleForm.billType === 1 || _vm.ruleForm.billType === 2
                        ? _c(
                            "el-table",
                            {
                              attrs: {
                                data: _vm.tradeBillCommonDtoList,
                                border: "",
                                size: "mini",
                                "header-cell-style": {
                                  background: "#d9dde1",
                                  color: "#0d0202",
                                  padding: 0,
                                },
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label:
                                    _vm.ruleForm.billType === 1
                                      ? "首件数（件）"
                                      : "首重（kg）",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请输入",
                                              size: "mini",
                                              min: 0,
                                              max: 999999,
                                            },
                                            model: {
                                              value: scope.row.firstNum,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "firstNum",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.firstNum",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2833251466
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "首费" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input-number", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请输入",
                                              size: "mini",
                                              min: 0,
                                              max: 999999,
                                            },
                                            model: {
                                              value: scope.row.firstFee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "firstFee",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.firstFee",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  500869274
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "是否条件包邮" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                placeholder: "请选择",
                                                size: "mini",
                                              },
                                              model: {
                                                value: scope.row.isShip,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "isShip",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.isShip",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "是",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "否",
                                                  value: 0,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1068599762
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "包邮条件", width: "300" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return scope.row.isShip === 1
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    margin: "0 3px",
                                                  },
                                                },
                                                [
                                                  _vm._v(" 满 "),
                                                  _c("el-input-number", {
                                                    staticStyle: {
                                                      width: "130px",
                                                    },
                                                    attrs: {
                                                      placeholder: "请输入",
                                                      size: "mini",
                                                      min: 0,
                                                      max: 999999,
                                                    },
                                                    model: {
                                                      value:
                                                        scope.row.shipCondition,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "shipCondition",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.shipCondition",
                                                    },
                                                  }),
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "70px",
                                                      },
                                                      attrs: { size: "mini" },
                                                      model: {
                                                        value: scope.row.isFee,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            scope.row,
                                                            "isFee",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "scope.row.isFee",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label:
                                                            _vm.ruleForm
                                                              .billType == 1
                                                              ? "件"
                                                              : "kg",
                                                          value: 0,
                                                        },
                                                      }),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "元",
                                                          value: 1,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "min-width": "30px",
                                                        margin: "0 3px",
                                                      },
                                                    },
                                                    [_vm._v("包邮")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          : undefined
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(" 提 交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.toBack()
                    },
                  },
                },
                [_vm._v(" 返回 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CitySelectModal", {
        attrs: { cityList: _vm.defaultCityList },
        on: { confirm: _vm.confirmArea },
        model: {
          value: _vm.showCitySelectModal,
          callback: function ($$v) {
            _vm.showCitySelectModal = $$v
          },
          expression: "showCitySelectModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }